import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

// Register the ScrollTrigger plugin with GSAP
gsap.registerPlugin(ScrollTrigger)

const scheduleSection = document.querySelector(".schedule-section")
const openScheduleModal = () => {
  scheduleSection.classList.add("active")
  document.body.style.overflow = "hidden"
}
const closeScheduleModal = () => {
  scheduleSection.classList.remove("active")
  document.body.style.overflow = "auto"
}

document
  .getElementById("schedule-session-btn")
  .addEventListener("click", openScheduleModal)

scheduleSection
  .querySelector(".close-modal-button")
  .addEventListener("click", closeScheduleModal)

// Loading Screen
document.addEventListener("DOMContentLoaded", function () {
  workProcessReveal()
  const loadingScreen = document.querySelector(".loading-screen")
  const progressPercent = document.querySelector(
    ".loading-screen .progress-percent"
  )
  const loadingText = document.querySelector(".loading-screen .loading-text")

  const images = document.querySelectorAll(".page-container img")
  let loadedImages = 0
  const totalImages = images.length

  function updateProgress() {
    const progress = (loadedImages / totalImages) * 100

    progressPercent.textContent = progress.toString() + "%"

    loadingText.style.backgroundPosition = `${100 - progress + "%"} 0`
    progressPercent.style.backgroundPosition = `${100 - progress + "%"} 0`

    if (loadedImages === totalImages) {
      const heroTitle = document.querySelectorAll(
        ".hero-container .hero-section .title div"
      )
      const heroBg = document.querySelector(".hero-container .abs-bg")

      const tl = gsap.timeline({
        delay: 1,
      })
      tl.to(loadingScreen, {
        opacity: 0,
        duration: 1,
        onStart: () => {
          window.scrollTo(0, 0)
        },
      })
        .to(heroTitle, {
          opacity: 1,
          duration: 2,
          stagger: 0.5,
        })
        .to(
          heroBg,
          {
            opacity: 1,
            duration: 1,
          },
          "-=1.5"
        )
    }
  }

  images.forEach((img) => {
    console.log(img.onload, img.complete)
    if (img.complete) {
      loadedImages++
      updateProgress()
    } else {
      img.onload = () => {
        console.log("loaded")
        loadedImages++
        updateProgress()
      }
      img.onerror = () => {
        console.log("error loading")
        loadedImages++
        updateProgress()
      }
    }
  })
})

// Tags slider
const showSlide = () => {
  const marquee = document.querySelector(".tags-section ")

  if (!marquee) return

  const marqueeContent = document.querySelector(
    ".tags-section .cards-container"
  )

  console.log({ marqueeContent })

  if (!marqueeContent) return

  //clone content and add it twice
  const marqueeContentClone = marqueeContent.cloneNode(true)
  const marqueeContentClone2 = marqueeContent.cloneNode(true)

  console.log({ marquee, marqueeContentClone, marqueeContentClone2 })

  marquee.appendChild(marqueeContentClone)

  // marquee.appendChild(marqueeContentClone2)

  gsap.to(marquee.children, {
    duration: 35,
    xPercent: -100,
    repeat: -1,
    ease: "linear",
  })
}

showSlide()

const workProcessReveal = () => {
  const cards = document.querySelectorAll(
    ".work-flow-section .flow-diagram .row .card"
  )
  cards.forEach((card) => {
    const colorFilledUnits = card.querySelectorAll(".color-filled")
    const colorStrokedUnits = card.querySelectorAll(".color-stroked")
    const dot = card.querySelector(".dot")
    const color = card.getAttribute("color")

    const scrollTrigger = {
      trigger: card,
      start: "top 68%",
      toggleActions: "play reverse play reverse",
    }

    gsap.to(colorFilledUnits, {
      duration: 0.7,
      fill: color,
      scrollTrigger,
    })

    gsap.to(colorStrokedUnits, {
      duration: 0.7,
      stroke: color,
      scrollTrigger,
    })

    gsap.to(dot, {
      backgroundColor: color,
      duration: 0.7,
      scrollTrigger,
    })
  })
}

const projectCardsAnimation = () => {
  const cards = document.querySelectorAll(".project-card")
  let activeIndex = 0
  let intervalId

  // Function to move the "active" class from one card to the next
  function moveActiveClass() {
    // Remove the "active" class from the current card
    cards[activeIndex].classList.remove("active")

    // Move to the next card, or reset to the first one if at the end
    activeIndex = (activeIndex + 1) % cards.length

    // Add the "active" class to the next card
    cards[activeIndex].classList.add("active")
  }

  // Function to start the interval animation
  function startAnimation() {
    intervalId = setInterval(moveActiveClass, 3000)
  }

  // Function to stop the interval animation
  function stopAnimation() {
    clearInterval(intervalId)
  }

  // Function to make hovered card active and pause the animation
  function onCardHover(index) {
    // Remove active class from currently active card
    cards[activeIndex].classList.remove("active")

    // Set hovered card as active
    activeIndex = index
    cards[activeIndex].classList.add("active")

    // Stop the automatic animation
    stopAnimation()
  }

  // Add event listeners to each card for hover effect
  cards.forEach((card, index) => {
    card.addEventListener("mouseenter", () => onCardHover(index))
    card.addEventListener("mouseleave", startAnimation)
  })

  // Set initial active class on the first card
  cards[0].classList.add("active")

  // Start the animation loop
  startAnimation()
}

projectCardsAnimation()

//Contact form handler
document
  .getElementById("submit-btn")
  .addEventListener("click", function (event) {
    event.preventDefault() // Prevent form from submitting the traditional way

    // Get the values from the form inputs
    const name = document.getElementById("name").value
    const projectName = document.getElementById("project-name").value
    const message = document.getElementById("additional-message").value
    const recipient = "david.oc@yahoo.com"

    // Create the subject and body for the email
    const subject = `${name} ${projectName ? "from " + projectName : ""}`
    const body = encodeURIComponent(message) // Encode the message to handle special characters

    // Open the default mail client with the pre-composed email
    window.location.href = `mailto:${recipient}?subject=${subject}&body=${body}`
  })

const onMouseMove = (e, card) => {
  const cardRect = card.getBoundingClientRect()
  const cursorLeft = e.clientX - cardRect.x
  const cursorTop = e.clientY - cardRect.y
  const cursor = card.querySelector(".cursor")

  if (
    (window.innerWidth || document.documentElement.clientWidth) > 768 &&
    !(cursorLeft > cardRect.width) &&
    !(cursorLeft < 0) &&
    !(cursorTop > cardRect.height) &&
    !(cursorTop < 0)
  ) {
    cursor.style.display = "block"
    cursor.style.left = cursorLeft + "px"
    cursor.style.top = cursorTop + "px"
  } else {
    cursor.style.display = "none"
  }
}

// Project cards cursor
const projectCards = document.querySelectorAll(".project-card")
projectCards.forEach((card) => {
  card.addEventListener("mousemove", (e) => onMouseMove(e, card))
})

//Mobile Nav Menu toggle
const navMenu = document.querySelector(".mobile-nav-menu")
const navContent = navMenu.querySelector(".menu-content") // The content inside the menu
const duration = 0.5
const borderDelay = 0.2 // Delay for border radius

const showNavmenu = () => {
  const tl = gsap.timeline()
  tl.to(navMenu, { x: "0", duration })
    .to(
      navMenu,
      {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
        duration,
        delay: borderDelay,
      },
      "<" // start border animation at the same time as x animation
    )
    .fromTo(
      navContent,
      { opacity: 0 },
      { opacity: 1, duration: 0.3 },
      ">0.1" // small delay after the menu is fully revealed
    )
}

const hideNavMenu = () => {
  const tl = gsap.timeline()
  tl.to(navContent, { opacity: 0, duration: 0.3 })
    .to(navMenu, { x: "100%", duration })
    .to(
      navMenu,
      {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
        duration,
        delay: borderDelay,
      },
      "<" // start border animation at the same time as x animation
    )
}

document.getElementById("nav-toggler").addEventListener("click", showNavmenu)
document
  .getElementById("close-nav-menu-btn")
  .addEventListener("click", hideNavMenu)
navContent
  .querySelectorAll(".links li")
  .forEach((link) => link.addEventListener("click", hideNavMenu))
